<template>
  <div class="">
    <el-breadcrumb class="breadcrumb-title"
      separator="/">
      <el-breadcrumb-item>组织管理</el-breadcrumb-item>
      <el-breadcrumb-item>访客记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div class="title">
        访客记录
        <i class=" iconfont iconyuangongguanli icon_gongguanli"
          style="margin-left:5px;color:#D8D8D8;"></i>
        <span> {{memberCount}}</span>
      </div>
      <div class="flex-row mb20 ml20">
        <SearchInput placeholderStr="请输入姓名或手机号"
          @updateSearchList="updateSearchList" />
        <div class="pointer  ml10 blue"
          @click="onClickRoleExplain">角色权限说明
          <i class="el-icon-info"></i>
        </div>
      </div>
      <el-table :data="tableData"
        :header-cell-style="{ background: '#F5F7FA', color: '#606266' }">
        <el-table-column prop="nickname"
          label="姓名"
          width="180">
        </el-table-column>
        <el-table-column prop="company_name"
          label="公司"
          width="180">
        </el-table-column>
        <el-table-column prop="position"
          label="职位"> </el-table-column>
        <el-table-column prop="phone"
          label="手机号"> </el-table-column>
        <el-table-column prop="email"
          label="邮箱"> </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <div type="text"
              style="color: #3468FE;"
              v-if="scope.row.status == 1"
              size="small">
              已启用
            </div>
            <div type="text"
              v-if="scope.row.status == 0"
              style="color:#F56C6C"
              size="small">
              已停用
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div @click="onOptItem(scope.row)"
              class="blue pointer">编辑</div>
          </template>

        </el-table-column>

      </el-table>
      <el-drawer :visible.sync="isRightDrawer"
        direction="rtl"
        custom-class="demo-drawer"
        :before-close="close">
        <RightDrawerContent v-if="isRightDrawer"
          :editForm=curItem
          @onSaveFormSubmit=onSaveFormSubmit
          @onChangeStatusSubmit=onChangeStatusSubmit
          @onChangeIdentity=onChangeIdentity></RightDrawerContent>

      </el-drawer>
      <ChangeIdentityDialog ref="refChangeIdentityDialog"
        @onChangeIdentitySubmit=onChangeIdentitySubmit
        :type.sync=identityType
        :departmentList=departmentList
        :editForm=curItem></ChangeIdentityDialog>

      <el-pagination v-if="memberCount > 0" @current-change="handleCurrentChange"
        :current-page="curPage"
        :page-size="pageSize"
        :page-sizes="[pageSize]"
        layout="prev, pager, next, sizes, jumper , total"
        background
        :total="memberCount">
      </el-pagination>
    </el-card>
    <RoleExcplainDialog ref="refRoleExcplainDialog"></RoleExcplainDialog>
  </div>
</template>

<script>
import request from "@request/api/index";
import SearchInput from "@components/SearchInput";
import RightDrawerContent from "./components/RightDrawerContent";
import ChangeIdentityDialog from "./components/ChangeIdentityDialog";
import RoleExcplainDialog from "@components/RoleExplainDialog.vue";
export default {
  components: {
    SearchInput,
    RightDrawerContent,
    ChangeIdentityDialog,
    RoleExcplainDialog,
  },
  data() {
    return {
      memberCount: 0,
      tableData: [],
      searchData: [],
      curPage: 1,
      pageSize: 10,
      pageData: [],
      curItem: {},
      orginCurItem:{},
      isRightDrawer: false,
      isDialog: false,
      departmentList: [],
      identityType: 0,
    };
  },
  created() {
    this.loadData();
    this.getDepartmentList();
    const abc = localStorage.getItem("abc");

    console.log(abc);
  },
  watch:{
     pageData () {
      let page = this.pageData.length / this.pageSize + 1
      if (this.pageData.length % this.pageSize === 0) {
        page--
      }
      if (this.curPage > 1 && this.curPage > page) {
        this.curPage = page
      }
      this.handlePagingList()
    }
  },
  methods: {
    close() {
      this.isRightDrawer = false;
      this.isDialog = false;
      this.curItem = {};
      console.log(this.isDialog);
    },
    onClickRoleExplain() {
      this.$refs.refRoleExcplainDialog.open();
    },
    async loadData() {
      let params = {
        name: "",
        page: this.curPage,
        page_size: this.pageSize,
      };
      const {
        data: {
          data: { count, list },
        },
      } = await request.visitor.getVisitorList(params);
      this.memberCount = count;
      this.tableData = list;
      this.searchData = list;
      this.pageData = list;
    },
    updateSearchList(str) {
      let data = [];
      this.searchData.filter((item) => {
        if (item.nickname.indexOf(str) >= 0 || item.phone.indexOf(str) >= 0) {
          data.push(item);
        }
      });
      this.pageData = data;
      this.tableData = data;
    },
    onOptItem(item) {
      this.orginCurItem = item;
      this.curItem = {...item};
      this.isRightDrawer = true;
    },
    onSaveFormSubmit(item) {
      console.log("item", item);
      const params = {
        name: item.nickname,
        user_id: item.user_id,
        company_name: item.company_name,
        position: item.position,
        phone: item.phone,
      };
      request.visitor.editVisitorInfo(params).then(({ data }) => {
        const { code } = data;
        if (code === 1000) {
          this.orginCurItem = Object.assign(this.orginCurItem,{...item})
          this.$message.success("编辑成功！");
          this.close();
        }
      });
    },
    onChangeStatusSubmit(status) {
      console.log("item", status);
      const params = {
        status,
        user_id: this.curItem.user_id,
      };
      request.visitor.changeVisitorStatus(params).then(({ data }) => {
        const { code } = data;
        if (code === 1000) {
          this.orginCurItem.status = status;
          this.$message.success("操作成功!");
          this.close();
        }
      });
    },
    onChangeIdentity(type) {
      this.identityType = type;
      this.$refs.refChangeIdentityDialog.open();
    },
    onChangeIdentitySubmit(item) {
      console.log(item);
      const role_type =
        this.$constant.identity.member == this.identityType ? "" : "external";
      const params = {
        position: item.position,
        department_id: item.department_id,
        type: role_type,
        user_id: this.curItem.user_id,
      };
      request.visitor.changeVisitorIdentity(params).then(({ data }) => {
        const { code } = data;
        if (code === 1000) {
          this.tableData.splice( this.tableData.findIndex(item=>item.id === this.curItem.id),1);
          this.pageData.splice( this.pageData.findIndex(item=>item.id === this.curItem.id),1);
          this.memberCount = this.pageData.length;
          this.$message.success("操作成功!");
          this.$refs.refChangeIdentityDialog.close();
          this.close();
        }
      });
    },
    getDepartmentList() {
      request.organization.requestSearchDepartment({}).then((res) => {
        this.departmentList = res.data.data.list;
      });
    },
    handleCurrentChange(val) {
      this.curPage = val;
      this.handlePagingList();
    },
    handlePagingList() {
      const start = (this.curPage - 1) * this.pageSize
      const end = start + this.pageSize
      this.tableData = this.pageData.slice(start, end)
    },
  },
};
</script>
<style lang="scss" scoped>
.el-card {
  border-radius: 8px;
  min-height: 900px;
  position: relative;
}
.title {
  margin-bottom: 20px;
  margin-left: 33px;
  height: 22px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}
</style>