<template>
  <el-dialog title='角色权限说明'
    :visible.sync='isShow'
    :before-close="close">
    <div style="line-height:35px">
      <h4>内部成员(可拥有企业管理后台权限)</h4>

      <p>创建项目 、上传文件、审阅、评论、下载</p>

      <h4>外部联系人(加入项目则成为外部联系人)</h4>

      <p> 上传文件、审阅、评论、下载</p>

      <h4>访客(只能通过分享查看审阅页面)</h4>

      <p> 审阅、评论、下载</p>

      <h4>其他权限说明</h4>

      <p>项目文件的邀请、下载、分享、复制、删除、权限在创建项目时,对“管理员”和“成员”时进行分配管理.

        创建分享可对访问密码、访问期限、评论、下载、转存权限进行设定,设定后,所有角色将按照分享设定进行访问和操作.</p>

      <p>企业管理权限在企业管理后台进行“管理员权限”的添加和分配.</p>

      <h4> 媒资库权限说明</h4>

      <p>查看：可查看煤资库</p>

      <p>上传编辑：创建文件夹、上传文件、重命名文件和文件夹、设定文件标签、添加文件描述、标记文件夹</p>

      <p> 标签管理：新增标签、修改标签、删除标签</p>

      <p> 复制、移动、下载、分享、删除、：复制、移动、下载、分享、删除、文件和文件夹</p>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "RoleExplainDialog",
  data() {
    return { isShow: false };
  },
  methods: {
    open() {
      this.isShow = true;
    },
    close() {
      this.isShow = false;
    },
  },
};
</script>