<template>
  <div
    :class="['search-engine-wrapper', 'flex-row-space-between', styleMode, isInputFocus ? 'focus' : '']"
  >
    <input
      class="search-engine-input"
      type="text"
      v-model="searchInputText"
      :placeholder="placeholderStr"
      @focus="bindFocusEvent"
      @blur="bindBlurEvent"
    />
    <span
      v-show="isShowSearchIcon"
      class="iconfont iconsousuo search-engine-img"
    ></span>
    <span
      v-show="!isShowSearchIcon"
      class="iconfont iconquxiao_quxiao search-engine-img"
      @click="onCancelKeywords"
    ></span>
  </div>
</template>
<script>
export default {
  props: {
    resetFlag: Number,
    placeholderStr: String,
    styleMode: {
      type: String,
      default: "dark", // 'dark' 'light'
    },
    mode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchInputText: "", //搜索框输入内容
      isShowSearchIcon: true, //是否显示搜索图标
      isInputFocus: false, //输入框是否获得焦点
    };
  },
  created() {},
  watch: {
    searchInputText() {
      this.isShowSearchIcon = this.searchInputText === "";
      this.$emit("updateSearchList", this.searchInputText);
    },
    resetFlag(){
      this.searchInputText = ""
    }
  },
  methods: {
    //清空搜索栏内容
    onCancelKeywords() {
      this.searchInputText = "";
    },
    bindFocusEvent() {
      this.isInputFocus = true;
      this.$emit("focus");
    },
    //搜索框失去焦点
    bindBlurEvent() {
      this.isInputFocus = false;
      this.isShowSearchIcon = this.searchInputText === "";
    },
  },
};
</script>

<style lang="scss" scoped>
.search-engine-wrapper {
  position: relative;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &.focus {
    border: 1px solid #3468FE;
  }
  &.light {
    background: #e7e7e7;
    &.focus {
      background-color: #ffffff;
      border: 1px solid #3c43ff;
    }
  }
  .search-engine-input {
    width: 85%;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #080b19;
    line-height: 20px;
    border: 0;
    outline: none;
  }
  .search-engine-img {
    margin-right: 5px;
    font-size: 18px;
    color: #b3b9c7;
    cursor: pointer;
  }
  input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
  input:-moz-placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
  input::-moz-placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
  input::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
}
</style>