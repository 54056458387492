<template>
  <div class="demo-drawer__content">
    <el-form :model="editForm"
      :rules="rules"
      ref="refSaveForm">
      <el-form-item label="姓名:"
        prop="nickname"
        :label-width="formLabelWidthRight">
        <el-input :disabled="isNameDisab"
          class="search-input-dialog"
          type="text"
          placeholder="请输入姓名"
          v-model="editForm.nickname"></el-input>
      </el-form-item>
      <el-form-item label="手机号:"
        prop="phone"
        :label-width="formLabelWidthRight">
        <el-input :disabled="isPhoneDisab"
          class="search-input-dialog"
          type="text"
          placeholder="请输入手机号"
          v-model="editForm.phone"></el-input>
      </el-form-item>
      <el-form-item label="邮箱:"
        prop="email"
        :label-width="formLabelWidthRight">
        <el-input :disabled="isEmailDisab"
          class="search-input-dialog"
          type="text"
          placeholder="请输入邮箱"
          v-model="editForm.email"></el-input>
      </el-form-item>
      <el-form-item label="公司:"
        prop="company_name"
        :label-width="formLabelWidthRight">
        <el-input class="search-input-dialog"
          type="text"
          placeholder="请输入公司"
          v-model="editForm.company_name"></el-input>
      </el-form-item>
      <el-form-item label="职位:"
        prop="position"
        :label-width="formLabelWidthRight">
        <el-input class="search-input-dialog"
          type="text"
          placeholder="请输入职位"
          v-model="editForm.position"></el-input>
      </el-form-item>
    </el-form>
    <div class="demo-drawer__footer"
      style="text-align:center;margin-top:50px;">
      <el-button type="primary"
        class="buttonColor"
        @click="onSaveSubmit">保存</el-button>
      <el-button class="suspend"
        v-if="editForm.status == 1"
        @click="onSaveStatus(0)">停用</el-button>
      <el-button class="suspend"
        v-if="editForm.status == 0"
        @click="onSaveStatus(1)">启用</el-button>
      <el-button type="primary"
        class="addBtn"
        @click="onSaveIdentity(1)">转为成员</el-button>
      <el-button type="primary"
        style="width:max-content"
        @click="onSaveIdentity(2)">转为外部联系人</el-button>
    </div>
  </div>

</template>
<script>
export default {
  name: "RightDrawerContent",
  props: {
    editForm: Object,
    departmentList: Array,
  },
  data() {
    return {
      formLabelWidthRight: "80px",
      isNameDisab: true, //this.editForm.nickname ? true : false,
      isEmailDisab: true,//this.editForm.email ? true : false,
      isPhoneDisab: true,//this.editForm.phone ? true : false,
      rules: {
        nickname: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20个字符",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入电话号码",
            trigger: "blur",
          },
          {
            pattern: /^1[0-9]{10}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        email: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        company_name: [
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20个字符",
            trigger: "blur",
          },
        ],
        top_name: [
          {
            required: true,
            message: "请选择组织",
            trigger: "blur",
          },
        ],
        position: [
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    onSaveSubmit() {
      this.$refs.refSaveForm.validate((valid) => {
        if (valid) {
          this.$emit("onSaveFormSubmit", this.editForm);
        }
      });
    },
    onSaveStatus(type) {
      const text =
        type == 0 ? "停用后该账号将暂停使用" : "启用后该账号将恢复使用";
      this.$confirm(`${text}, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$emit("onChangeStatusSubmit", type);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    onSaveIdentity(role) {
      let type =
        role === 1
          ? this.$constant.identity.member
          : this.$constant.identity.externalMember;

      this.$emit("onChangeIdentity", type);
    },
  },
};
</script>