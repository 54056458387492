<template>
  <el-dialog width="40%"
    :title="'转为'+role"
    :visible.sync="isShow"
    :before-close=close>
    <el-form :model="formItem"
      ref="MembershipForm">
      <el-form-item>
        <div class="line">转为{{role}}后，该账号将获得企业{{role}}相关权限，建议谨慎使用。</div>
      </el-form-item>
      <el-form-item v-if="isShowDep"
        label="部门: "
        prop="top_name"
        :label-width="formLabelWidth"
        :rules="[
           {
             required: true,
             message: '请输入选择部门',
             trigger: 'change'
           }
        ]">
        <el-input
          class="input-max-width"
          v-model="formItem.top_name"
          name="top_name"
          @focus="showModalTree = true"
          clearable
          placeholder="请选择组织"
          autocomplete="off"></el-input>
        <transition name="el-zoom-in-top">
          <div class="modalTree"
            v-if="showModalTree">
            <el-tree ref="tree"
              :data="departmentList"
              default-expand-all
              @node-click="handleNodeClick"
              :expand-on-click-node="false"
              style="left:60px;width:57%;z-index:90"></el-tree>
          </div>
        </transition>
        <div class="treeModal"
          @click="showModalTree = false"
          v-if="showModalTree"></div>
      </el-form-item>
      <el-form-item v-if="isMember" label="职位: "
        prop="position"
        :label-width="formLabelWidth"
        :rules="handlePositionRules">
        <el-input class="search-input-dialog"
          type="text"
          placeholder="请输入职位"
          maxlength="10"
          v-model="formItem.position"
          autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer"
      class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary"
        class="buttonColor"
        @click="onSaveSubmit('MembershipForm')">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "ChangeIdentityDialog",
  props: {
    editForm: Object,
    departmentList: Array,
    type: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      isShow: false,
      formLabelWidth: "120px",
      formItem: {
        department_id: 0,
        top_name: "",
        position: "",
      },
      showModalTree: false,
      rulesPosition:[
           {
             required: true,
             message: '请输入职位',
             trigger: 'blur'
           },
          {
            min: 2,
            max: 20,
            message: '长度在 2 到 20个字符',
            trigger: 'blur'
          }
        ] 
    };
  },
  computed: {
    isShowDep() {
      return this.$constant.identity.member == this.type;
    },
    role() {
      return this.type == this.$constant.identity.member
        ? "成员"
        : "外部联系人";
    },
    isMember(){
      return this.type == this.$constant.identity.member;
    },
    handlePositionRules(){
       if(this.type == this.$constant.identity.member){
         return this.rulesPosition;
       }else{
         return [];
       }
    }
  },
  methods: {
    open() {
      this.isShow = true;
    },
    close() {
      this.formItem = {
        top_name: "",
        position: "",
      };
      this.$refs.MembershipForm.clearValidate();
      this.isShow = false;
    },
    onSaveSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("onChangeIdentitySubmit", Object.assign(this.editForm,this.formItem));
        }
      });
    },
    handleNodeClick(node) {
      this.formItem.department_id = node.id;
      this.formItem.top_name = node.label;
      this.showModalTree = false;
      // this.$refs.MembershipForm.clearValidate();
      this.$forceUpdate();
    },
  },
};
</script>